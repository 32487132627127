<template>
  <svg
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.5429 0.542892L11.9571 1.95711L6.16421 7.75L11.9571 13.5429L10.5429 14.9571L3.33579 7.75L10.5429 0.542892Z"
      fill="currentColor"
    />
  </svg>
</template>
